import React from 'react'
import { Col, Row } from 'reactstrap'
import JpAvatar from '../general/JpAvatar'
import JpDatePicker from '../inputs/JpDatePicker'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

const TabActions = ({
  filters=[],
  state,
  setStatusType,
  setCurrentPage,
  ActionButton,
  filterDate,
  start_date,
  setStartDate,
  end_date,
  setEndDate,
  classNameRow
}) => {
  const { t } = useTranslation()
  const classNameForRow = classNameRow ? classNameRow : 'align-items-center justify-content-end'
  return (
    <Row className={` ${classNameForRow}`}>
      {filterDate && (
        <Col sm="5" md="4" lg="3" xl="3" className="d-none d-xxl-flex table-filters px-0">
          <JpDatePicker
            id="contractStartDate"
            value={start_date}
            setPicker={date => setStartDate(date.format('YYYY-MM-DD'))}
            className={'enabled-joobpay'}
          />

          <JpDatePicker
            id="contractEndDate"
            value={end_date}
            minDate={moment(start_date).format('YYYY-MM-DD')}
            maxDate={moment().format('YYYY-MM-DD')}
            setPicker={date => setEndDate(date.format('YYYY-MM-DD'))}
            className={'enabled-joobpay ms-1'}
          />
        </Col>
      )}
      <Col sm="7" md="8" lg="6" xl="7" className=" d-none d-md-flex table-filters ps-0 justify-content-end">
        {filters.map(item => (
          <div
            key={item.icon}
            className={`d-flex align-items-center table-filter${state === item.state ? '__active' : ''}`}
            onClick={() => {
              setStatusType(state === item.state ? '' : item.state)
              setCurrentPage(0)
            }}
          >
            <JpAvatar
              icon={<i class={`${item.icon}`}></i>}
              color={state === item.state || state === '' ? item.color : 'light-secondary'}
              className={`table-filter__btn me-lg-0 me-1`}
            />
            <span className="table-filter__text me-1 ms-25">{t(`${item.text}`)}</span>
          </div>
        ))}
      </Col>
      {ActionButton && (
        <Col md="3" lg="2" xl="2" className="d-flex justify-content-end justify-content-md-center">
          <ActionButton />
        </Col>
      )}
    </Row>
  )
}

export default TabActions
