import { API_URL } from '../configs/environment'
import SliderCaptcha from '@slider-captcha/react'
import { useTranslation } from 'react-i18next'

const Captcha = ({setValue, setCanContinue}) => {
  const { t } = useTranslation()
  return (
    <SliderCaptcha
      create={`${API_URL}/auth/captcha/create`}
      verify={`${API_URL}/auth/captcha/verify`}
      text={{ anchor: t('validation.Text-PleaseVerifyCaptcha') }}
      callback={token => {
        setValue('captchaToken', token)
        setCanContinue(true)
      }}
    />
  )
}

export default Captcha