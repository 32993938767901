import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import { Card } from 'reactstrap'
import { JpButton, SpinnerLoader } from '../../Intelli-utilities'

const JpPDFVertical = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null)
  const lastPage = useRef(null)
  const [visible, setVisible] = useState(false)
  const [scrollMode, setScrollMode] = useState('bottom')
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setVisible(false)
    setScrollMode('bottom')
  }

  const scrollToBottom = () => {
    const footer = document.getElementsByTagName('footer')[0]

    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' })
    } else {
      lastPage.current?.scrollIntoView({ behavior: 'smooth' })
    }
    setVisible(false)
    setScrollMode('top')
  }

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', () => {
        if (!visible && window.scrollY > 100) {
          setVisible(true)
        }
      })
    }
  }, [])

  return (
    <>
      {!!numPages && visible && (
        <div className="scroll-top d-block mb-2">
          <JpButton
            text=" "
            type="group"
            className={`rounded-circle px-1 py-75 ${scrollMode === 'top' && 'mb-3'}`}
            options={{
              onClick: scrollMode === 'bottom' ? scrollToBottom : scrollToTop,
              customIcon: <FontAwesomeIcon icon={scrollMode === 'bottom' ? faArrowDown : faArrowUp} />
            }}
          />
        </div>
      )}
      <Card className="p-0 rounded-bottom pdfViewer zoomed-in">
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} loading={<SpinnerLoader />}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              scale={3}
              key={`page_${index + 1}`}
              id={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              className="d-flex border border-top-2"
              canvasRef={index + 1 === numPages ? lastPage : null}
            />
          ))}
        </Document>
      </Card>
    </>
  )
}

export default JpPDFVertical
