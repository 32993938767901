import { Input, InputGroup, InputGroupText } from 'reactstrap'
import JpLabel from './JpLabel'
const JpCurrencyInput = ({ name, invalid = false, optionsInput = {}, title, disabled = false, currency = 'US$' }) => {
  return (
    <>
      {title && <JpLabel labelOptions={{ for: name }}>{title}</JpLabel>}
      <InputGroup>
        <InputGroupText>{currency}</InputGroupText>
        <Input name={name} type="number" disabled={disabled} invalid={invalid} addon {...optionsInput} />
      </InputGroup>
    </>
  )
}

export default JpCurrencyInput
