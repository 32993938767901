import { BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip, Chart } from 'chart.js'
import { Bar } from 'react-chartjs-2'

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const colors = {
  success: '#81C995',
  danger: '#EA5455',
  warning: '#FF9F43',
  info: '#0198FE'
}

const JpHorizontalBars = ({ data, labels, customBarsColors, color = 'success', barLabelFormatter, tickFormatter }) => {
  while (data.length < 3) {
    data.push('0.01')
  }

  while (labels.length < 3) {
    labels.push('')
  }

  const barColors = labels.map(label => (label === '' ? '#E9E9E9' : colors[color]))
  // ** Chart Options

  const options = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 500,
      onProgress({ chart }) {
        const ctx = chart.ctx
        ctx.font = '12px Poppins, sans-serif'
        ctx.textAlign = 'left'
        /* ctx.textBaseline = 'right' */

        chart.data.datasets.forEach(function (dataset, i) {
          const meta = chart.getDatasetMeta(i)
          meta.data.forEach(function (bar, index) {
            if (dataset.data[index] > 0) {
              const data = dataset.data[index]

              /* if (bar.options.backgroundColor === '#E9E9E9') {
                return
              } */
              if (barLabelFormatter) {
                ctx.fillText(barLabelFormatter(data), bar.x + 40, bar.y + 5)
              } else {
                ctx.fillText(labels[index] === '' ? '' : labels[index], 10, bar.y - 25)
              }

              if (data < 20 && labels[index] !== '') {
                ctx.fillText(`${data}%`, bar.x + 10, bar.y + 5)
              }
            }
          })
        })
      }
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false
        },
        max: Math.max(...data) + 0.1 * Math.max(...data),
        min: 0,
        ticks: {
          display: false
        }
      },
      y: {
        border:{
          color: '#fff'
        },
        grid: {
          display: false,
          drawBorder: false,
          drawTicks: false
        },
        ticks: {
          display: true,
          mirror: true,
          z: 999,
          padding: 5,
          color: '#fff',
          font: {
            size: 12,
            weight: 500,
            family: 'Poppins, sans-serif'
          },
          ...(!!tickFormatter ? { callback: (value, index, ticks) => tickFormatter(value, index, ticks, labels) } : {})
        }
      }
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: labels.every(label => label === '') ? false : true,
        xAlign: 'top',
        yAlign: 'left',
        displayColors: false,
        callbacks: {
          label: ctx => {
            return `${ctx.raw}%`
          }
        }
      }
    }
  }

  // ** Chart data
  const barData = {
    labels,
    datasets: [
      {
        backgroundColor: customBarsColors ? customBarsColors : barColors,
        borderColor: 'transparent',
        maxBarThickness: 25,
        borderRadius: { topRight: 5, bottomRight: 5 },
        color: 'black',
        data,
        datalabels: {
          anchor: 'center',
          align: 'center'
        }
      }
    ]
  }
  return <Bar data={barData} options={options} height={200} redraw={true} />
}

export default JpHorizontalBars
