// ** Third Party Components
import Chart from 'react-apexcharts'
import { MoreVertical } from 'react-feather'
import { parseMinutesToHours, JpAvatar } from '@Intelli/utilities'
import parseName from '../../functions/parseName'
// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, CardBody, UncontrolledTooltip, Badge, Progress } from 'reactstrap'
import NoData from '../NoData'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'
import { useTranslation } from 'react-i18next'

const JpAdvanceCardModal = ({
  data = [],
  title = '',
  maxShown = 4,
  loading = false,
  bodyText = true,
  customTooltipPercent = '',
  withProgress = false,
  classNameRow = '',
  cardBodyClassName = ''
}) => {
  const colors = {
    primary: '#0364b6',
    success: '#81C995',
    warning: '#ff9f43',
    danger: '#ea5455',
    info: '#5791e9',
    secondary: '#82868b',
    yellow: '#FFE180',
    light: '#D0D2D8',
    green: '#28C76F'
  }
  const { t } = useTranslation()
  const colorsArray = Object.keys(colors)

  const mostUsedApps = data?.map(i => {
    return {
      ...i,
      avatar: i?.media ? i.media?.location : i.name,
      title: i?.name,
      subtitle: ' ',
      time: i.count ? i.count : parseMinutesToHours(i.time),
      chart: {
        type: 'radialBar',
        series: [
          {
            data: Number(i?.percent)
          }
        ],
        height: 30,
        width: 30,
        options: {
          grid: {
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15
            }
          },
          colors: [colors.primary.main],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%'
              },
              track: {
                background: '#28C76F'
              },
              dataLabels: {
                showOn: 'top',
                name: {
                  show: false
                },
                value: {
                  show: false
                }
              }
            }
          },
          stroke: {
            lineCap: 'round'
          }
        }
      }
    }
  })

  const TitlesCardBody = () => {
    return (
      <CardBody className="employee-task pb-0 mb-0 d-flex justify-content-between align-items-center">
        <span className="col-6 fw-bold ps-3">{t('apps')}</span>
        <span className="col-3 text-end fw-bold">{t('time')}</span>
        <span className="col-3 text-end fw-bold">{t('percent')}</span>
      </CardBody>
    )
  }

  const renderTasks = () => {
    const data = mostUsedApps?.length >= maxShown ? mostUsedApps?.slice(0, maxShown) : mostUsedApps
    return data?.map((app, index) => {
      return (
        <div key={index} className={`employee-task d-flex justify-content-between align-items-center ${classNameRow}`}>
          <div className={`d-flex  ${withProgress ? 'col-4' : 'col-6'}`}>
            {app?.media?.location || app?.media?.icon ? (
              <JpAvatar
                color={`light-info`}
                icon={app?.media?.icon}
                img={app?.media?.location}
                imgClassName="avatar-cover cursor-default"
                className="me-1 "
              />
            ) : (
              <JpAvatar
                color={`light-info`}
                content={app.name ? `${parseName(app.name.toLowerCase())}` : '-'}
                initials
                className="me-1 cursor-default"
              />
            )}
            <div className="my-auto text-truncate">
              <small className="mb-0 ">{app.title ? app.title : '-'}</small>
              <small>{app.subtitle ? app.subtitle : '-'}</small>
            </div>
          </div>
          {withProgress && (
            <div className="col-4">
              <Progress
                id={`progressBar-${index}`}
                value={parseInt(app.percent).toFixed(0)}
                color={`${colorsArray[index]}`}
              />
              <UncontrolledTooltip target={`progressBar-${index}`}>{`${app.percent}%`}</UncontrolledTooltip>
            </div>
          )}
          <div className="d-flex align-items-center text-center">
            <small className="font-monospace">{app.time ? app.time : '-'}</small>
          </div>
          <div className="d-flex align-items-center text-center">
            {app.color ? (
              <Badge color={`${app.color}`}>
                <small className="font-monospace">{app.percent ? app.percent : '0'}%</small>
              </Badge>
            ) : (
              <small className="font-monospace">{app.percent ? app.percent : '-'}%</small>
            )}
          </div>
        </div>
      )
    })
  }
  if (loading) return <JpCardSkeleton bodyText={bodyText} className="h-100" loading={loading} graph />
  return (
    <>
      {data?.length === 0 ? (
        <Card className="card-employee-task h-100 mb-0 ">
          <CardHeader>
            <CardTitle tag="h4">{title}</CardTitle>
          </CardHeader>
          <CardBody className="d-flex align-items-center justify-content-center">
            <div className="d-flex justify-content-center align-items-center rounded placeholder bg-white">
              <NoData color="secondary" size="3x" className="my-3" />
            </div>
          </CardBody>
        </Card>
      ) : (
        <Card className="card-employee-task h-100 mb-0">
          {title && (
            <CardHeader>
              <CardTitle tag="h4">{title}</CardTitle>
            </CardHeader>
          )}
          <TitlesCardBody />
          <CardBody className={`${cardBodyClassName}`}>{renderTasks()}</CardBody>
        </Card>
      )}
    </>
  )
}

export default JpAdvanceCardModal
