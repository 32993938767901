import moment from 'moment'

import ReactApexChart from 'react-apexcharts'
import { getColorHex } from '../../functions/getColorHex'

const dataIsEmpty = (data, stacked) => {
  if (stacked) {
    return data.every(obj => {
      // Check if the 'data' property exists and is either empty or contains only zeros
      return obj.data && (obj.data.length === 0 || obj.data.every(value => value === 0))
    })
  } else {
    return data.every(value => value === 0)
  }
}

const EMPTY_DATA = Array.from({ length: 7 }, () => 100)
const getEmptyData = (data, stacked) => (stacked ? [{ data: EMPTY_DATA }] : EMPTY_DATA)

const JpBarsV1 = ({
  data,
  xTooltipFormatter,
  yTooltipFormatter,
  seriesLabels,
  stacked,
  categories,
  colors,
  chartHeight,
  showXToolTip
}) => {
  const emptyData = dataIsEmpty(data, stacked)
  const EMPTY_COLORS = [getColorHex('secondary', false, 0.1)]
  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: emptyData ? false : stacked,
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        distributed: !stacked || emptyData,
        columnWidth: '42%',
        endingShape: 'rounded',
        startingShape: 'rounded'
      }
    },
    legend: { show: false },
    tooltip: {
      enabled: !emptyData,
      x: {
        show: showXToolTip,
        formatter: xTooltipFormatter ? xTooltipFormatter : val => val
      },
      y: {
        formatter: yTooltipFormatter ? yTooltipFormatter : val => val,
        title: {
          formatter() {
            return null
          }
        }
      },
      marker: { show: false }
    },
    dataLabels: { enabled: false },
    colors: emptyData
      ? EMPTY_COLORS
      : colors
      ? colors
      : ['#0198FE', '#0198FE', '#0198FE', '#0198FE', '#0198FE', '#0198FE', '#28c76f'],
    states: {
      hover: {
        filter: { type: 'none' }
      },
      active: {
        filter: { type: 'none' }
      }
    },
    grid: {
      show: false,
      padding: {
        top: -4,
        left: -7,
        right: -5,
        bottom: -12
      }
    },
    xaxis: {
      categories: categories
        ? categories
        : seriesLabels
        ? seriesLabels
        : Array.from({ length: 7 }, (_, i) => moment().subtract(i, 'days').format('dd')).reverse(),
      axisTicks: { show: false },
      axisBorder: { show: false },
      tickPlacement: 'on',
      labels: {
        style: {
          colors: '#D0D2D8',
          fontFamily: 'Poppins, Quicksand, Questrial, Outfit, sans-serif',
          fontSize: '0.9125rem'
        },
        offsetY: stacked ? -5 : 0
      }
    },
    yaxis: { show: false }
  }

  const chartData = emptyData ? getEmptyData(data, stacked) : data

  return (
    <ReactApexChart
      type="bar"
      width={'100%'}
      height={chartHeight ? chartHeight : 170}
      series={
        stacked
          ? chartData
          : [
              {
                data: chartData
              }
            ]
      }
      options={options}
    />
  )
}

export default JpBarsV1
