// ** React Imports
import { useState } from 'react'

// ** Custom Components
// ** Third Party Components
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'

// ** Reactstrap Imports
import { Card, CardBody, CardText } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const TinyChartStats = props => {
  // ** Props
  const { title, stats, options, series, type, height, className = '', spanText, subtitlePercentage, subtitle } = props

  return (
    <Card className="card-tiny-line-stats mb-0">
      <CardBody className={`${className}`}>
        <div className="d-flex justify-content-between aling-items-center">
          <h6 className="ms-1">{title}</h6>
          <div>
            {spanText && <span className="font-small-3">{spanText}</span>}
            {subtitlePercentage === false ? null : (
              <div className="d-flex">
                {subtitlePercentage && <CardText className={`me-50 mb-0 ${subtitlePercentage > 0 ? 'text-success' : 'text-danger'}`}>
                  {subtitlePercentage > 0 ? '+' : ''}
                  {Math.round(subtitlePercentage)}%
                </CardText>}
                <CardText tag="small" className="mb-0">
                  {subtitle}
                </CardText>
              </div>
            )}
          </div>
        </div>

        <h2 className="fw-bolder mb-1 ms-1">{stats}</h2>
        <Chart options={options} series={series} type={type} height={height} />
      </CardBody>
    </Card>
  )
}

// ** PropTypes
TinyChartStats.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  stats: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired
}

// ** Default Props
TinyChartStats.defaultProps = {
  height: 100
}

const JpOrdersBarChart = ({ title, total, values = [], months = [], spanText, subtitlePercentage, subtitle, className='', height=90 }) => {
  const valuesComplete = [...values]
  const monthsComplete = [...months]
  while (monthsComplete?.length < 5) {
    let objectValues = 0
    monthsComplete.push(objectValues)
  }
  while (valuesComplete?.length < 5) {
    let objectValues = 0
    valuesComplete.push(objectValues)
  }
  const { t } = useTranslation()

  const [data, setData] = useState({
    title: title,
    statistics: total ? `US$ ${Number(total).toFixed(2)}` : '',
    series: [
      {
        name: t('value'),
        data: values.length > 0 ? valuesComplete?.reverse() : [0, 0, 0, 0, 0] //obj value
      }
    ]
  })

  const options = {
    chart: {
      stacked: true,
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        top: -15,
        bottom: -15
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        colors: {
          backgroundBarColors: ['#d0d2d8'],
          backgroundBarRadius: 5
        }
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#f9c851'],
    xaxis: {
      labels: {
        show: false
      },
      categories: monthsComplete?.reverse(), //obj key
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false
    },
    tooltip: {
      enabled: true,
      x: {
        show: true
      }
    }
  }

  return data !== null ? (
    <TinyChartStats
      height={height}
      type="bar"
      options={options}
      title={data.title}
      stats={data.statistics}
      series={data.series}
      className={className}
      spanText={spanText}
      subtitlePercentage={subtitlePercentage}
      subtitle={subtitle}
    />
  ) : null
}

export default JpOrdersBarChart
