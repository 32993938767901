import { BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip, Chart } from 'chart.js'
import { Bar } from 'react-chartjs-2'

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const colors = {
  success: '#81C995',
  danger: '#EA5455',
  warning: '#FF9F43',
  info: '#0198FE',
  primary: '#0364B6',
  blue: '#00cfe8',
  cyan: '#0dcaf0',
  green: '#28c76f',
  teal: '#20c997'
}

const JpStackedBars = ({ data, labels, seriesColors, seriesNames, tooltipFormatters, height, plugin }) => {

  const barBackTrack = {
    id: 'barBackTrackEdit',
    beforeDatasetsDraw: (chart, args, plugins) => {
      const {
        chartArea,
        ctx
      } = chart

      const { width, height, bottom } = chartArea

      const firstBarData = chart.getDatasetMeta(0).data

      const barThickness = firstBarData[0]?.width

      ctx.save()

      ctx.fillStyle = '#E9E9E9'

      const xPositions = firstBarData?.map(item => item.x)
      xPositions.forEach((xPos, index) => {
        ctx.beginPath()
        ctx.roundRect(xPos - barThickness * 0.5, bottom, barThickness, -height, [0,0,20,20])
        ctx.fill()
      })
    }
  }
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        stacked: true,
        ticks: {
          display: true
        },
        border: {
          display: false
        }
      },
      y: {
        stacked: true,
        display: false,
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label(context) {
            return ` ${context.dataset.label}: ${tooltipFormatters[context.datasetIndex](
              context.parsed.y,
              context.dataIndex
            )}`
          },
          title() {
            return null
          }
        }
      }
    }
  }

  const barData = {
    labels,
    datasets: [
      ...data.map((val, index) => {
        return {
          label: seriesNames[index],
          //   label: `Series ${index}`,
          backgroundColor: colors[seriesColors[index]],
          borderColor: 'transparent',
          borderSkipped: false,
          maxBarThickness: 8,
          borderRadius:
            index === data.length - 1
              ? {
                  topLeft: 10,
                  topRight: 10
                }
              : index === 0
              ? { topLeft: 0, topRight: 0 }
              : 0,
          data: data[index],
          key: seriesNames[index].toLowerCase().replace(' ', '-')
        }
      })
    ]
  }

  return <Bar  plugins={plugin ?[barBackTrack] : []} data={barData} options={options} height={height} />
}

export default JpStackedBars
