import { ButtonGroup } from 'reactstrap'
import PropTypes from 'prop-types'

const JpButtonGroup = ({ children, className }) => {
  return <ButtonGroup className={`${className}`}>{children}</ButtonGroup>
}

JpButtonGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  className: PropTypes.string
}

export default JpButtonGroup
