import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Row } from 'reactstrap'
import UtilitiesAPI from '../../services/API'

const ErrorContent = ({ img }) => {
  const { t } = useTranslation('errors')
  return (
    <Row className="misc-inner p-1 p-sm-3">
      <div className="w-100 text-center">
        <h2 className="mb-1">{t('render.title')}</h2>
        <p className="mb-2">{t('render.description')} </p>
      </div>
      <img style={{ maxHeight: '400px' }} src={img} className="img-fluid" alt="RenderError" />
    </Row>
  )
}

const JpErrorBoundary = ({ children, img }) => {
  const API = new UtilitiesAPI()
  const logError = (error, info) => {
    // Do something with the error, e.g. log to an external API
    API.reportRenderError({
      message: `WEB - ${error.message}`,
      stack: error.stack,
      info: JSON.stringify(info),
      module: window.location.pathname
    })
  }
  return (
    <ErrorBoundary fallback={<ErrorContent img={img} />} onError={logError}>
      {children}
    </ErrorBoundary>
  )
}

export default JpErrorBoundary
