import PropTypes from 'prop-types'

const tags = {
    table: 'span',
    card: 'span',
    'avatar-subtitle': 'small',
    'avatar-name': 'span',
    'card-title': 'h4',
    'small-text': 'small',
    'disabled-text': 'span',
    'p-text' : 'p',
    'span-table-date': 'span',
    'span-table-text': 'span',
    'p-bold': 'p'
}

const classNames = {
  table: 'm-0 p-0',
  card: 'm-0 p-0',
  'avatar-subtitle': 'm-0 p-0 user-status text-secondary',
  'avatar-name': 'm-0 p-0 user-name',
  'card-title': 'm-0',
  'small-text': '',
  'disabled-text': 'disabled-joobpay d-block',
  'p-text': 'm-0',
  'span-table-date': 'd-block text-truncate text-secondary',
  'span-table-text': 'd-block text-truncate',
  'p-bold': 'fw-bolder m-0 mt-75'
}


const JpText = ({children, type, className='', id=''}) => {

    const Tag = tags[type] || 'span'

    return <Tag id={id} className={`${classNames[type] || ''} ${className}`}>{children}</Tag>
}

JpText.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    type: PropTypes.oneOf(Object.keys(tags))
}

export default JpText