import { components } from 'react-select'
import { Badge } from 'reactstrap'
import { priorityColors } from '../../constants/projects/projectStateColors/index'

const OptionComponentPriorityValue = ({ children, ...props }) => {
    const { data } = props

    return (
        <components.SingleValue {...props}>
            <Badge pill color={`light-${priorityColors[data.value]}`}>
                {data.label}
            </Badge>
        </components.SingleValue>
    )
}

export default OptionComponentPriorityValue