import MaskedInput from 'react-text-mask'

function insertBeforeLastTwo(arr) {
    arr.splice(arr.length - 2, 0, 'h', ' ')
    return arr
}

function JpInputHoursCustom({ hour, setHour, id, placeholder = '20h', limitHour }) {
    const handleHourChange = e => {
        const inputValue = e.target.value
        setHour(inputValue)
    }

    const mask = function (rawValue) {
        if (!!rawValue) {
            const inputValueTransform = rawValue
                .replace(/[^\d]/g, ' ')
                .trim()
                .replace(/\s+/g, '').length

            const maskArray = []

            for (let index = 0; index < inputValueTransform; index++) {
                maskArray.push(/\d/)
            }

            if (inputValueTransform > 2) {
                insertBeforeLastTwo(maskArray)
            }

            if (inputValueTransform <= 2) {
                maskArray.push('h')
            } else {
                maskArray.push('m')
            }

            return maskArray
        } else {
            return [/\d/, 'h']
        }
    }

    return (
        <>
            <MaskedInput
                mask={value => mask(value)}
                placeholder={placeholder}
                value={hour}
                id={id}
                onChange={handleHourChange}
                className="form-control"
            />
        </>
    )
}

export default JpInputHoursCustom
