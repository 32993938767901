export const projectStateColors = {
    IN_PROGRESS: 'primary',
    PLANNED: 'cyan',
    FINALIZED: 'green',
    ARCHIVED:'purple'
}

export const priorityColors = {
    LOW: 'success',
    MEDIUM: 'warning',
    HIGH: 'danger',
    CUSTOM: 'info'
}