import { useState } from 'react'
import Select from 'react-select'
import { getDataSet, reduce } from 'iso3166-2-db'
import i18next from 'i18next'

const listOfCountries = reduce(getDataSet(), i18next.language)

const StateInput = ({ setValue, country, disabled, field, watch }) => {
  const [regions, setRegions] = useState(listOfCountries[country]?.regions?.sort((a, b) => (a.name > b.name ? 1 : -1)))

  watch((data, { name }) => {
    if (name === 'country') {
      const orderedRegions = listOfCountries[data.country.value]?.regions?.sort((a, b) => (a.name > b.name ? 1 : -1))
      setRegions(orderedRegions)
      setValue('region', {
        value: orderedRegions[0]?.iso,
        label: orderedRegions[0]?.name
      })
    }
  })

  return (
    <Select
      options={regions?.map(region => ({ value: region.iso, label: region.name }))}
      className={disabled ? 'disabled-joobpay' : ''}
      isDisabled={disabled}
      {...field}
    />
  )
}

export default StateInput
