// ** Reactstrap Imports
import {  PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap'

const JpPopover = ({ target = '', popoverBody, popoverTitle = '' }) => {
  return (
    <UncontrolledPopover trigger="legacy" placement="bottom" target={target} hideArrow={true}>
      <PopoverHeader className="bg-light-secondary border-0">
        {popoverTitle}
      </PopoverHeader>
      <PopoverBody className="pt-75 pb-50 border-bottom-0">{popoverBody && popoverBody}</PopoverBody>
    </UncontrolledPopover>
  )
}
export default JpPopover
