const parseHoursForMinutes = hourToParse => {
    const hoursSplit = hourToParse.split(' ')
    const minutesTransform = hoursSplit
        .find(item => item.includes('m'))
        ?.replace(/[a-zA-Z]/g, '')

    const timeParsedToSend = hoursSplit
        .map(val => {
            const valueInNumber = val.replace(/[a-zA-Z]/g, '').replace(/_/g, '')
            if (val.includes('h')) {
                return Number(valueInNumber) * 60
            }
            return Number(valueInNumber)
        })
        .reduce((a, b) => a + b, 0)

    return {
        timeParsedToSend,
        minutesTransform
    }
}

export default parseHoursForMinutes