
import Select, { components } from 'react-select'
import ReactCountryFlag from 'react-country-flag'
import { getDataSet, reduce } from 'iso3166-2-db'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const listOfCountries = reduce(getDataSet(), i18next.language)

const OptionComponent = ({ data, ...props }) => {

    
    if (data.value === 'ALL') return <components.Option {...props} />

    return (
      <components.Option {...props}>
        <ReactCountryFlag
          svg
          className="country-flag flag-icon me-75"
          countryCode={data.value}
        />
        {data.label}
      </components.Option>
    )
}

const SingleValue = ({children, ...props}) => {
  const {data} = props

  return (
      <components.SingleValue {...props}>
          <ReactCountryFlag
              svg
              className="country-flag flag-icon me-75"
              countryCode={data.value}
          />
          {children}
      </components.SingleValue>)
}

const JpCountryInput = ({field, isClearable, includeAllCountriesOption, disabled, enableCountrys, customOptions}) => {

    const {t}= useTranslation()
  
    let countryOptions = Object.keys(listOfCountries).filter(key => listOfCountries[key].regions.length !== 0).map(key => ({value: listOfCountries[key].iso, label: listOfCountries[key].name}))
    
    if (includeAllCountriesOption) countryOptions.unshift({value: 'ALL', label: t('all')})

    if (enableCountrys){
       countryOptions = countryOptions.filter(item => enableCountrys.includes(item.value))
    } 

    
    if (customOptions){
        countryOptions = [...countryOptions, ...customOptions]
    }

      return (
        <Select
          options={countryOptions}
          isClearable={isClearable}
          placeholder= {t('input.select.placeHolder', {ns:'global'})}
          noOptionsMessage={()=> t('input.select.noOptionsMessage', {ns:'global'})}
          loadingMessage={()=> t('input.select.loadingMessage', {ns:'global'})}
          className={disabled ? 'disabled-joobpay' : ''}
          isDisabled={disabled}
          components={{
            Option: OptionComponent,
            SingleValue
          }}
          {...field}
        />
      )
}

export default JpCountryInput