// ** React Imports
import { Fragment, useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// ** Third Party Components
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Reactstrap Imports
import {
  Form,
  Row,
  Button,
  Modal
} from 'reactstrap'

// ** Custom Components
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { QueryClient, QueryClientProvider, useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import UtilitiesAPI from '../services/API'

const MySwal = withReactContent(Swal)

const TwoFactorModal = ({isModalOpen}) => {
  const { t } = useTranslation()

  const SignupSchema = yup.object().shape({
    vcode_1: yup.string().required('Please enter the 6 digits'),
    vcode_2: yup.string().required('Please enter the 6 digits'),
    vcode_3: yup.string().required('Please enter the 6 digits'),
    vcode_4: yup.string().required('Please enter the 6 digits'),
    vcode_5: yup.string().required('Please enter the 6 digits'),
    vcode_6: yup.string().required('Please enter the 6 digits')
  })

  const API = new UtilitiesAPI()

  // ** Hooks

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SignupSchema)
  })

  const [tokenId, setTokenId] = useState('')

  const handleWarning = (warningTitle, warningText) => {
    return MySwal.fire({
      title: warningTitle,
      text: warningText,
      icon: 'warning',
      confirmButtonText: 'Go back',
      customClass: {
        confirmButton: 'btn btn-warning'
      },
      buttonsStyling: false
    })
  }

  const { data: token, isError: tokenHasError, isLoading: tokenisLoading } = useQuery('2fa', () => API.send2faCode())

  const {mutate: setSessionTwoFactor } = useMutation((token) => API.setSessionTwoFactor(token.token_id, token.token),
    {
      onSuccess: response => {
      },
      onError: error => {
        console.log(error)
      }
    }
  )
  const {mutate: TwoFactorValidate} = useMutation(token => API.validate2faCode(token), {
    onSuccess: (response) => {
      setSessionTwoFactor({two_factor_id: response.two_factor_id})
    },
    onError: error => {
      console.log(error)
    }
  })

  const onSubmit = data => {
    const code =
      getValues('vcode_1') +
      getValues('vcode_2') +
      getValues('vcode_3') +
      getValues('vcode_4') +
      getValues('vcode_5') +
      getValues('vcode_6')
    if (tokenId !== '') {
      TwoFactorValidate({ token_id: tokenId, token: code })
    } else {
      handleWarning(
        'Something is wrong',
        'Please press the get code button and set the correct code on the field'
      )
    }
  }

  const handleOtpPaste = e => {
    const otp_inputs = document.querySelectorAll('.auth-input-wrapper input')
    const paste = (e.clipboardData || window.clipboardData).getData('text')
    const value = paste.split('')
    if (value.length === 6) {
      otp_inputs.forEach((input, index) => {
        input.value = value[index]
        setValue(`vcode_${index + 1}`, value[index])
      })
    }
  }

  const handleOtp = e => {
    const input = e.target
    const value = input.value
    const field_index = input.tabIndex

    input.value = value ? value[0] : ''

    if (value.length > 0 && field_index < 5) {
      input.nextElementSibling.focus()
    }

    if (e.key === 'Backspace' && field_index > 0) {
      input.previousElementSibling.focus()
    }

    setValue(`vcode_${field_index + 1}`, value)
  }

  useEffect(() => {
    if (!tokenisLoading && !tokenHasError) {
      setTokenId(token)
    }
  }, [token, tokenHasError, tokenisLoading])

  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <Modal isOpen={isModalOpen}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="justify-content-center text-center mt-3 ">
            <h6>Two Factor Verification</h6>
            <p>A code has been sent to yoy mail and phone</p>
            <small>Please type your 6 digit security code</small>
            <div className="auth-input-wrapper d-flex align-items-center justify-content-center mt-1">
              <input
                {...register('vcode_1')}
                tabIndex={0}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                autoFocus
                type="tel"
                maxLength="1"
                className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                  errors.vcode_1?.message ? 'is-invalid' : ''
                }`}
              />
              <input
                {...register('vcode_2', { required: true })}
                tabIndex={1}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                type="tel"
                maxLength="1"
                className="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1"
              />
              <input
                {...register('vcode_3', { required: true })}
                tabIndex={2}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                type="tel"
                maxLength="1"
                className="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1"
              />
              <input
                {...register('vcode_4', { required: true })}
                tabIndex={3}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                type="tel"
                maxLength="1"
                className="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1"
              />
              <input
                {...register('vcode_5', { required: true })}
                tabIndex={4}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                type="tel"
                maxLength="1"
                className="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1"
              />
              <input
                {...register('vcode_6', { required: true })}
                tabIndex={5}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                type="tel"
                maxLength="1"
                className="form-control auth-input height-50 text-center numeral-mask mx-25 mb-1"
              />
            </div>
            {errors.vcode_1 && (
              <small className="text-danger">{errors.vcode_1.message}</small>
            )}
          </Row>
          <div className="d-flex justify-content-between mt-2">
            <Button type="submit" color="primary" className="btn-next">
              <span className="align-middle d-sm-inline-block d-none">{t("Verify")}</span>
            </Button>
          </div>
        </Form>
      </Modal>
    </QueryClientProvider>
  )
}

export default TwoFactorModal
