import { useTranslation } from 'react-i18next'

const NoData = ({ color, size, text, textColor }) => {
  const { t } = useTranslation()

  return (
    <div className={`d-flex flex-column text-center text-${color} my-2`}>
      <i class={`fak fa-solid fa-cloud-xmark fa-${size}`}></i>
      {text ? (
        <small className="mt-2 ">{text}</small>
      ) : (
        <small className={`${textColor ? `text-${textColor}` : ''}`}>{t('noData')}</small>
      )}
    </div>
  )
}

export default NoData
