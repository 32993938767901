import { components } from 'react-select'
import { Badge } from 'reactstrap'
import { priorityColors } from '../../constants/projects/projectStateColors/index'

const OptionComponentPrioritySelect = ({ data, ...props }) => {
    return (
        <components.Option {...props}>
            <Badge pill color={`light-${priorityColors[data.value]}`}>
                {data.label}
            </Badge>
        </components.Option>
    )
}

export default OptionComponentPrioritySelect