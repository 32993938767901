import { Card, CardBody, CardHeader, CardText, CardTitle, Progress, UncontrolledTooltip } from 'reactstrap'

import JpAvatar from './JpAvatar'
import JpPercentageText from './JpPercentageText'
import JpSmallGraphSkeleton from '../skeletons/JpSmallGraphSkeleton'

export const Stats = props => {
  const { type, statSize, stat } = props

  const Tags = {
    xl: 'h1',
    lg: 'h2',
    md: 'h3',
    sm: 'h4',
    xs: 'h6'
  }

  const Tag = Tags[statSize]

  return (
    <Tag className="m-0 fw-bolder">
      {stat} {type ? <small>/ {type}</small> : null}
    </Tag>
  )
}

const JpStatsIconCard = props => {
  const {
    per,
    title,
    subtitle,
    subtitlePercentage,
    icon,
    iconSize,
    iconBgColor,
    iconDirection,
    stat,
    statSize,
    statPosition,
    spanText,
    className,
    bodyClassName,
    header,
    contractorsInfo,
    contractorsInfoSubtitle,
    calc = false,
    loading = false,
    swapBgColor = false,
    customBadge = false,
    id = '',
    tooltipText
  } = props

  if (loading) return <JpSmallGraphSkeleton />

  return (
    <Card className={`h-100 ${className}`}>
      {iconDirection === 'top' && (
        <CardHeader className="justify-content-center justify-content-md-start pb-0">
          <JpAvatar color={iconBgColor} icon={icon} size={iconSize} className="me-1" />
        </CardHeader>
      )}
      {header && <div className="ms-5 me-5 mt-1">{header}</div>}
      <CardBody  className={`d-flex justify-content-center align-items-center ${bodyClassName}`}>
        {iconDirection === 'left' && <JpAvatar color={iconBgColor} icon={icon} size={iconSize} className="me-1" />}
        <div>
          <h5 id={id} className="mb-0 fw-normal mb-25">{title}</h5>
          {statPosition != 'top' ? (
            <>
              <Stats statSize={statSize} stat={stat} type={per} />
              {spanText && <span className="font-small-3">{spanText}</span>}
              {subtitlePercentage === false ? null : (
                <div className="d-flex my-50">
                  {customBadge ? (
                    customBadge
                  ) : (
                    <JpPercentageText
                      swapBgColor={swapBgColor}
                      percentage={parseFloat(subtitlePercentage)}
                      periodText={subtitle}
                    />
                  )}
                </div>
              )}
              {contractorsInfo && (
                <div className="d-flex">
                  <small
                    className={`me-50 mb-0 ${calc ? (contractorsInfo > 0 ? 'text-success' : 'text-danger') : null}`}
                  >
                    {calc ? (contractorsInfo > 0 ? '+' : '') : null}
                    {contractorsInfo}
                  </small>
                  <CardText tag="small" className="mb-0">
                    {contractorsInfoSubtitle}
                  </CardText>
                </div>
              )}
            </>
          ) : (
            <>
              {spanText && <span className="font-small-3">{spanText}</span>}
              {subtitlePercentage === false ? null : (
                <div className="d-flex mt-25">
                  {customBadge ? (
                    customBadge
                  ) : (
                    <JpPercentageText
                      swapBgColor={swapBgColor}
                      percentage={parseFloat(subtitlePercentage)}
                      periodText={subtitle}
                    />
                  )}
                </div>
              )}
              {contractorsInfo && (
                <div className="d-flex">
                  <small
                    className={`me-50 mb-0 ${calc ? (contractorsInfo > 0 ? 'text-success' : 'text-danger') : null}`}
                  >
                    {calc ? (contractorsInfo > 0 ? '+' : '-') : null}
                    {contractorsInfo}
                  </small>
                  <CardText tag="small" className="mb-0">
                    {contractorsInfoSubtitle}
                  </CardText>
                </div>
              )}
              <Stats statSize={statSize} stat={stat} type={per} />
            </>
          )}
        </div>
        {iconDirection === 'right' && <JpAvatar color={iconBgColor} icon={icon} size={iconSize} />}
      </CardBody>
      {tooltipText && <UncontrolledTooltip placement='right' target={id}>{tooltipText}</UncontrolledTooltip>}
    </Card>
  )
}

export default JpStatsIconCard
