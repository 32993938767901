import SweetModal from './SweetModal'
import i18next from 'i18next'
import UtilitiesAPI from '../services/API'

const errorHandler = async error => {
  const API = new UtilitiesAPI()

  const translate = (text, ns = 'errors') => {
    return i18next.t(text, { ns })
  }

  const getUrl = () => {
    const url = window.location.href

    if (url.includes('backoffice')) return '/backoffice/home'

    if (url.includes('admin')) return '/admin/home'
  }

  if (!error) return

  if (error.code) {
    switch (error.code) {
      case 'AUSE001':
      case 'AUSE002':
      case 'VLMD009':
      case 'VLMD003':
        const url = new URLSearchParams(window.location.search)
          const newUrl = `/auth/login?logout=ok${url ? `&${url.toString()}` : ''}`
          ;async () => {
            API.logout()
          }
          setTimeout(() => (window.location.href = newUrl), 1000)
          break
      case 'AUSE018':
      case 'AUSE019':
        // eslint-disable-next-line no-unused-expressions
        ;async () => {
          await API.logout()
          window.location.href = '/'
        }
        setTimeout(() => (window.location.href = '/auth/login?logout=ok'), 2000)
        break
      case 'AUSE003':
        SweetModal(
          'warning',
          translate('titles.atention'),
          translate(`codes.${error.code}`),
          translate('buttons.tryAgain')
        )
        break
      case 'AUSE005-1':
      case 'AUSE005-5':
      case 'AUSE005-10':
        SweetModal('warning', translate('titles.atention'), translate(`codes.AUSE005`), translate('buttons.ok'))
        break
      case 'AUSE000':
      case 'AUSE006':
      case 'AUSE007':
      case 'AUSE008':
      case 'AUSE009':
      case 'AUSE011':
      case 'AUSE012':
      case 'AUSE013':
      case 'ADMSV014':
      case 'ADMSV016':
      case 'ADMSV019':
      case 'ADMSV020':
      case 'ADMSV021':
      case 'ADMSV022':
        SweetModal('warning', translate('titles.atention'), translate(`codes.${error.code}`), translate('buttons.ok'))
        break
      case 'AUSE014':
      case 'AUSE015':
      case 'AUSE021':
      case 'AUSE024':
      case 'VLMD004':
        await API.logout()
        SweetModal(
          'warning',
          translate('titles.error'),
          translate(`codes.${error.code}`),
          translate('buttons.ok'),
          () => {
            window.location.href = '/'
          }
        )
        break
      case 'VLMD002':
        SweetModal(
          'warning',
          translate('titles.error'),
          translate(`codes.${error.code}`),
          translate('buttons.ok'),
          () => {
            window.location.href = getUrl()
          }
        )
        break
      case 'AUSE016':
      case 'ADMSV027':
        break
      case 'AUSE017':
      case 'VFSV011':
      case 'VLMD001':
        break
      case 'ADMSV007':
        SweetModal(
          'warning',
          translate('titles.atention'),
          translate(`codes.${error.code}`),
          translate('buttons.ok'),
          ({ isConfirmed }) => {
            if (isConfirmed) window.history.back()
          }
        )
        break
      case 'PGSV004':
        SweetModal(
          'warning',
          translate('titles.atention'),
          translate(`codes.${error.code}`),
          translate('buttons.ok'),
          ({ isConfirmed }) => {
            if (isConfirmed) window.location.href = '/admin/home'
          }
        )
        break
      default:
        SweetModal('warning', translate('titles.error'), translate(`codes.${error.code}`), translate('buttons.ok'))
        break
    }
  }
}

export default errorHandler
