import moment from 'moment-timezone'

const acceptedFormats = ['YYYY-MM-DD','MMM DD', 'L', 'LL', 'LLL', 'LLLL', 'LT', 'LTS', 'l', 'll', 'lll', 'llll', 'lt', 'lts']

/**
 * @param {string} dateString - Date string to be localized
 * @param {string} format - Format of the date string
 * @returns A localized date string
 * @example localizeDate("2021-01-01", "L") // 01/01/2021
 * @example localizeDate("2021-01-01", "LT") // 12:00 AM
 * @example localizeDate("2021-01-01", "LTS") // 12:00:00 AM
 */

const localizeDate = (dateString = Date(), format = 'L') => {
  if (!acceptedFormats.includes(format)) {
    throw new Error(`Format ${format} is not supported`)
  }

  return moment.tz(dateString, moment.tz.guess(true)).format(format)
}

// Moment API based functions

const getMomentShortDate = (dateString = Date()) => {
  return localizeDate(dateString, 'l') // 1/1/2021
}

const getMomentShortTime = (dateString = Date()) => {
  return localizeDate(dateString, 'LT') // 12:00 AM
}

const getMomentShortDateTime = (dateString = Date()) => {
  return localizeDate(dateString, 'LLL') // 01/01/2021 12:00 AM
}

const getMomentLongDate = (dateString = Date()) => {
  return localizeDate(dateString, 'll') // Jan 1, 2021
}

const getMomentMediumDate = (dateString = Date()) => {
  return localizeDate(dateString, 'MMM DD') // Jan 26
}

const getMomentLongTime = (dateString = Date()) => {
  return localizeDate(dateString, 'LTS') // 12:00:00 AM
}

// Intl API based functions
const getIntlShortDate = (dateString = Date()) => {
  return moment(dateString).toDate().toLocaleDateString([], { dateStyle: 'short' }) // 1/1/2021
}

const getIntlShortTime = (dateString = Date()) => {
  return moment(dateString).toDate().toLocaleTimeString([], { timeStyle: 'short' }) // 12:00 AM
}

const getIntlOnlyYearMonth = (dateString = Date()) => {
  const date = moment(dateString).toDate()
  return `${date.toLocaleDateString([], { month: 'short' }).replace('.', '')}/${date.toLocaleDateString([], {
    year: '2-digit'
  })}` //Jan/23
}

const getIntlOnlyMonth = (dateString = Date()) => {
  const date = moment(dateString).toDate()
  return date.toLocaleDateString([], { month: 'short' }).replace('.', '') //Jan
}

const getIntlShortDateTime = (dateString = Date()) => {
  return moment(dateString).toDate().toLocaleString([], { dateStyle: 'short', timeStyle: 'short' }) // 1/1/2021 12:00 AM
}

const getIntlLongDate = (dateString = Date()) => {
  return moment(dateString).toDate().toLocaleDateString([], { dateStyle: 'medium' }) // Jan 1, 2021
}

const getLocaleFormat = () => {
  return new Intl.DateTimeFormat().resolvedOptions().locale // en-US
}

export {
  getIntlOnlyYearMonth,
  localizeDate,
  getMomentShortDate,
  getMomentShortTime,
  getMomentShortDateTime,
  getMomentLongDate,
  getMomentLongTime,
  getIntlShortDate,
  getIntlShortTime,
  getIntlShortDateTime,
  getIntlLongDate,
  getLocaleFormat,
  getIntlOnlyMonth,
  getMomentMediumDate
}
