import { Card, CardBody, CardHeader, CardText, CardTitle, Placeholder } from 'reactstrap'
import { NoData } from '../../Intelli-utilities'
import JpGraphSkeleton from './JpGraphSkeleton'

const JpCardSkeleton = ({ img = true, bodyText = true, noData = true, loading, graph = false, className='' }) => {
  return (
    <Card className={`${className}`}>
      <CardHeader>
        {!loading && <Placeholder color="light-secondary" className="col-6 cursor-default" size="lg" />}
        {loading && (
          <Placeholder className="col-6 cursor-default" size="lg" animation="glow">
            <Placeholder className="col-12 rounded " size="lg" />
          </Placeholder>
        )}
      </CardHeader>
      <CardBody>
        {img && !graph && (
          <>
            {!loading && (
              <div className="d-flex justify-content-center align-items-center rounded placeholder cursor-default">
                {noData && (
                  <div>
                    <NoData color="white" size="3x" textColor="secondary" />
                  </div>
                )}
              </div>
            )}
            {loading && (
              <div className="d-flex justify-content-center align-items-center rounded placeholder-glow cursor-default">
                <Placeholder color="light-secondary" className="col-12 rounded" size="lg" style={{ minHeight: 200 }} />
              </div>
            )}
          </>
        )}
        {graph && <JpGraphSkeleton />}
        {bodyText && (
          <CardText className={`d-flex flex-column mt-1 ${loading ? 'placeholder-glow' : ''}`}>
            <span className="placeholder col-7 mb-1 rounded cursor-default"></span>
            <span className="placeholder col-4 rounded cursor-default"></span>
          </CardText>
        )}
      </CardBody>
    </Card>
  )
}

export default JpCardSkeleton
