import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import capitalizeLabels from '../../functions/capitalizeLabels'
import { colors$ } from '../../Intelli-utilities'

const horizontalLegendOptions = ['right', 'left']
const verticalLegendOptions = ['top', 'bottom']

const JpPieGraph = ({
  graphData,
  graphLabels,
  graphColors,
  type = 'pie',
  tooltipFormatter,
  cropLongLabels = true,
  legendPosition = 'right',
  fourMaxShoun = false,
  legendShow = true,
}) => {
  const { t } = useTranslation()

  const emptyValues = Object.values(graphData?.filter(i => i !== 0))

  const colors = {
    primary: '#0364b6',
    success: '#81C995',
    warning: '#ff9f43',
    danger: '#ea5455',
    info: '#5791e9',
    secondary: '#82868b',
    yellow: '#FFE180',
    light: '#D0D2D8',
    green: '#28C76F'
  }

  const maxShown = !fourMaxShoun ? 3 : 4

  const pieData =
    graphData.length > maxShown
      ? graphData.slice(0, maxShown).concat(graphData.slice(maxShown).reduce((a, b) => a + b, 0))
      : graphData
  const pieLabels = graphLabels.length > maxShown ? graphLabels.slice(0, maxShown).concat(t('Others')) : graphLabels
  const pieColors = graphColors
    ? graphColors.length > maxShown
      ? graphColors
          .map(color => colors[color])
          .slice(0, maxShown)
          .concat(colors['yellow'])
      : graphColors.map(color => colors[color])
    : Object.values(colors).slice(0, maxShown).concat(colors['yellow'])

  const options = {
    plotOptions: {
      pie: {
        customScale: 0.8,
        donut: {
          size: '75%'
        }
      }
    },
    chart: {
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: legendShow,
      formatter: seriesName => [
        seriesName.length <= 10 || !cropLongLabels
          ? capitalizeLabels(seriesName.toLowerCase())
          : capitalizeLabels(`${seriesName?.substring(0, 10)}...`.toLocaleLowerCase())
      ],
      position: legendPosition,
       /* position: 'right', */
      /*  horizontalAlign: 'top', */
      horizontalAlign: horizontalLegendOptions.includes(legendPosition) ? 'top' : 'center',
      itemMargin: horizontalLegendOptions.includes(legendPosition)
        ? {
            horizontal: 0,
            vertical: 0
          }
        : { horizontal:5, vertical: 0 },
      offsetX: horizontalLegendOptions ? 5 : 0,
      offsetY: graphData.length > maxShown ? -5 : -15
    },
    // comparedResult: [2, -3, 8],
    labels: pieLabels?.map(i => capitalizeLabels(i)),
    stroke: { width: 0 },
    colors: pieColors,
    tooltip: {
      fontFamily: 'Poppins',
      x: {
        formatter: val => (tooltipFormatter ? tooltipFormatter(val) : val)
      },
      y: {
        formatter: val => (tooltipFormatter ? tooltipFormatter(val) : val)
      }
    },
    grid: {
      padding: {
        right: 0,
        bottom: 0,
        left: 0
      }
    }
    /* responsive: [
      {
        breakpoint: 1325,
        options: {
          chart: {
            height: 100
          }
        }
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 120
          }
        }
      },
      {
        breakpoint: 1065,
        options: {
          chart: {
            height: 100
          }
        }
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 120
          }
        }
      }
    ] */
  }

  const chartDataSkeleton = {
    series: [1],
    options: {
      chart: {
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false,
        position: 'right',
        horizontalAlign: 'top',
        /* fontFamily: 'Poppins', */
        itemMargin: {
          horizontal: 0,
          vertical: 0
        },
        offsetX: 5,
        offsetY: graphData.length > maxShown ? -5 : 0
      },
      // comparedResult: [2, -3, 8],
      labels: [t('noData')],
      stroke: { width: 0 },
      colors: ['#E9E9E9'],
      tooltip: {
        fontFamily: 'Poppins'
      },
      grid: {
        padding: {
          right: 0,
          bottom: 0,
          left: 0
        }
      },
      responsive: [
        {
          breakpoint: 1325,
          options: {
            chart: {
              height: 100
            }
          }
        }
      ]
    }
  }

  return (
    <>
      {graphData.length === 0 || emptyValues.length === 0 ? (
        <Chart
          options={chartDataSkeleton.options}
          series={chartDataSkeleton.series}
          type={type}
          height={120}
          width="100%"
        />
      ) : (
        <Chart
          options={options}
          series={pieData}
          type={type}
          height={horizontalLegendOptions.includes(legendPosition) ? 120 : undefined}
          width="90%"
        />
      )}
    </>
  )
}

export default JpPieGraph
