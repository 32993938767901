// ** Third Party Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

// ** Reactstrap Imports
import { Card, CardBody } from 'reactstrap'
  
const JpModuleCard = ({ icon, color, size, title, subtitle, className }) => {
  return (
    <Card className='text-center m-0 h-100'>
      <CardBody className={className}>
        <div className={`avatar p-50 m-0 mb-1 ${color ? `bg-light-${color}` : 'bg-light-primary'} ${size ? `avatar-${size}` : ''}`}>
            <span className='avatar-content'>
                <FontAwesomeIcon icon={icon} size="3x" />
            </span>
        </div>
        <h4 className='fw-bolder'>{title}</h4>
        <p className='card-text line-ellipsis'>{subtitle}</p>
      </CardBody>
    </Card>
  )
}

export default JpModuleCard

// ** PropTypes
JpModuleCard.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}
