import Chart from 'react-apexcharts'
import { colors$ } from '../../Intelli-utilities'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import apexchart from 'apexcharts'

const JpRadialBar = ({ colors, data = [80, 50, 35, 20], labels, tooltipFormatter, legendFormatter, rawData }) => {
  const { t } = useTranslation()
  const newLabels = labels ? labels : [`${t('active')}`, `${t('inactive')}`, `${t('disconnected')}`, `${t('onCall')}`]

  // ** Chart Options
  const defaultOptions = {
    animations: {
      enabled: false
    },
    colors: colors
      ? colors.map(color => colors$.value[color].main)
      : Object.values(colors$.value)?.map(color => color.main),
    plotOptions: {
      radialBar: {
        hollow: {
          size: '25%'
        },
        track: {
          margin: 15 - 5 * (data.length - 3 < 3 ? data.length - 3 : 2)
        },
        dataLabels: {
          show: false,
          name: {
            fontSize: '1rem',
            fontFamily: 'Montserrat'
          },
          value: {
            fontSize: '1rem',
            fontFamily: 'Montserrat'
          },
          total: {
            show: false,
            fontSize: '1rem',
            label: 'Comments',
            formatter() {
              return '80%'
            }
          }
        }
      }
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      y: {
        show: true,
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) =>
          !!tooltipFormatter ? tooltipFormatter(value, { series, seriesIndex, dataPointIndex, w }) : val
      }
    },
    grid: {
      padding: {
        top: -20,
        bottom: -20,
        left: -20,
        right: -20
      }
    },
    legend: {
      show: false,
      position: 'bottom',
      /* formatter: (seriesName, opts) => {
        return !!legendFormatter ? legendFormatter(seriesName, opts, rawData) : seriesName
      }, */
      customLegendItems: Object.keys(rawData)?.map(label => legendFormatter(label, [], rawData)) || []
    },
    stroke: {
      lineCap: 'round'
    },
    labels: newLabels,
    responsive: [
      {
        breakpoint: 1366,
        options: {
          chart: { height: 149 }
        }
      },
      {
        breakpoint: 992,
        options: {
          chart: { height: 180 }
        }
      },
      {
        breakpoint: 430,
        options: {
          chart: { height: 150 }
        }
      }
    ]
  }

  return <Chart id={'radial'} options={defaultOptions} series={data} type="radialBar" height={200} />
}

export default JpRadialBar
