import { Card, CardBody, CardHeader, CardTitle, CardText, CardFooter } from 'reactstrap'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'

const JpGraphCard = ({
  title,
  subtitle,
  graph,
  className,
  styles = {},
  cardHeaderClass,
  cardBodyClass,
  cardTitleClass,
  cardSubtitleClass,
  cardFooter,
  footerClassName,
  cardHeader,
  loading = false,
  subFooter,
  subFooterClassName='',
  border=false
}) => {
  if (loading) return <JpCardSkeleton className='h-100' loading={loading} graph />
  return (
    <Card className={className} style={styles}>
      <CardHeader className={`pb-0 ${cardHeaderClass}`}>
        <CardTitle tag="h4" className={cardTitleClass}>
          {title}
          {cardHeader && cardHeader}
        </CardTitle>
        {subtitle ? <CardText className={cardSubtitleClass}> {subtitle} </CardText> : <></>}
      </CardHeader>
      <CardBody className={`p-0 ${cardBodyClass}`}>{graph}</CardBody>
      {cardFooter && <CardFooter className={`${!border && 'border-0'} ${footerClassName}`}>{cardFooter}</CardFooter>}
      {subFooter && <CardFooter className={`border-0 ${subFooterClassName}`}>{subFooter}</CardFooter>}
    </Card>
  )
}

export default JpGraphCard
