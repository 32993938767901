import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col } from 'reactstrap'
import JpStackedBars from '../graphs/JpStackedBars'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'

const JpStackedBarsCard = ({
  data,
  labels,
  seriesNames,
  title,
  subtitle,
  cardBodyHeight = {},
  seriesColors,
  tooltipFormatters,
  height,
  bodyClassName,
  classNameCard,
  footer,
  additionalInfo = <></>,
  footerClassName,
  cols = false,
  loading = false,
  bodyText=false,
  plugin = false
}) => {
  if (loading) return <JpCardSkeleton bodyText={bodyText} className='h-100 p-0 m-0' loading={loading} graph />
  return (
    <Card className={classNameCard}>
      {title && (
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
      )}
      <CardBody
        className={`d-flex justify-content-center align-items-center pb-0 ${bodyClassName}`}
        style={cardBodyHeight}
      >
        {additionalInfo && additionalInfo}
        {cols ? (
          <Col xs="8" md="8" className={`h-100 d-flex align-items-end justify-content-end`}>
            <JpStackedBars
              data={data}
              tooltipFormatters={tooltipFormatters}
              labels={labels}
              seriesNames={seriesNames}
              seriesColors={seriesColors}
              height={height}
              plugin={plugin}
            />
          </Col>
        ) : (
          <JpStackedBars
            data={data}
            tooltipFormatters={tooltipFormatters}
            labels={labels}
            seriesNames={seriesNames}
            seriesColors={seriesColors}
            height={height}
            plugin={plugin}
          />
        )}
      </CardBody>
      {footer && <CardFooter className={footerClassName}>{footer}</CardFooter>}
    </Card>
  )
}

export default JpStackedBarsCard
