import Select, { components } from 'react-select'
import ReactCountryFlag from 'react-country-flag'
import { language$ } from '../../Intelli-utilities'
import i18next from 'i18next'

const Languages = [
  {
    countryCode: 'us',
    i18n: 'en',
    text: 'English'
  },
  {
    countryCode: 'es',
    i18n: 'es',
    text: 'Español'
  }
]

const OptionComponent = ({ data, ...props }) => {
  return (
    <components.Option {...props}>
      <ReactCountryFlag svg className="country-flag flag-icon me-75" countryCode={data.countryCode} />
      {data.label}
    </components.Option>
  )
}

const SingleValue = ({ children, ...props }) => {
  const { data } = props

  return (
    <components.SingleValue {...props}>
      <ReactCountryFlag svg className="country-flag flag-icon me-75" countryCode={data.countryCode} />
      {children}
    </components.SingleValue>
  )
}

const handleLangUpdate = (changeLanguage, lang) => {
  changeLanguage(lang)
  language$.next(lang)
}

const JpLanguageSelect = ({ changeLanguage }) => {

    const defaultLanguage = Languages.find(e => e.i18n === i18next.language)
  return (
    <Select
      hideSelectedOptions
      components={{
        Option: OptionComponent,
        SingleValue
      }}
      options={Languages.map(lang => ({
        value: lang.i18n,
        label: lang.text,
        ...lang
      }))}
      defaultValue={{...defaultLanguage, value: defaultLanguage.i18n, label: defaultLanguage.text}}
      onChange={({ value }) => handleLangUpdate(changeLanguage, value)}
    />
  )
}

export default JpLanguageSelect
