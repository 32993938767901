import Chart from 'react-apexcharts'

const options = {
  chart: {
    sparkline: { enabled: true }
  },
  stroke: { lineCap: 'round' },
  colors: ['#51e5a8'],
  background: '#51e5a8',
  states: {
    hover: {
      filter: { type: 'none' }
    },
    active: {
      filter: { type: 'none' }
    }
  },
  plotOptions: {
    radialBar: {
      endAngle: 90,
      startAngle: -90,
      hollow: { size: '64%' },
      track: {
        strokeWidth: '40%',
        background: '#ebe9f1'
      },
      dataLabels: {
        name: { show: false },
        value: {
          offsetY: -3,
          color: '#5e5873',
          fontFamily: 'Montserrat',
          fontSize: '1.50rem',
          fontWeight: '600'
        }
      }
    }
  },
  grid: {
    padding: {
      bottom: 15
    }
  },
  responsive: [
    {
      breakpoint: 1366,
      options: {
        chart: { height: 149 }
      }
    },
    {
      breakpoint: 992,
      options: {
        chart: { height: 199 }
      }
    },
    {
      breakpoint: 430,
      options: {
        chart: { height: 150 }
      }
    }
  ]
}

const JpProgressRadial = ({ value }) => <Chart options={options} series={[value]} type="radialBar" height={200} />

export default JpProgressRadial
