import { useTranslation } from 'react-i18next'
import { Button, UncontrolledTooltip } from 'reactstrap'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRotateLeft,
  faChevronLeft,
  faChevronRight,
  faPaperPlaneTop,
  faPenToSquare,
  faPlus,
  faRotate,
  faTrash,
  faXmark,
  faMoneyBill,
  faSpinnerThird,
  faFileArrowDown
} from '@fortawesome/pro-solid-svg-icons'
import { faFloppyDisk } from '@fortawesome/pro-regular-svg-icons'

const defaultProps = {
  color: 'info',
  buttonType: 'button',
  block: false,
  disabled: false,
  outline: false,
  options: {
    iconless: false,
    iconPosition: 'right',
    lightColor: false,
    onClick: () => {},
    textLess: false
  }
}

const buttonIcons = {
  save: faFloppyDisk,
  next: faChevronRight,
  back: faChevronLeft,
  send: faPaperPlaneTop,
  cancel: faXmark,
  delete: faTrash,
  add: faPlus,
  edit: faPenToSquare,
  update: faRotate,
  close: faXmark,
  submit: faPaperPlaneTop,
  reset: faArrowRotateLeft,
  'save&send': faPaperPlaneTop,
  skip: faChevronRight,
  pay: faMoneyBill,
  download: faFileArrowDown
}

const iconlessButtons = ['cancel', 'save']

const defaultColorsByType = {
  cancel: 'danger',
  skip: 'warning',
  action: 'link',
  save: 'green'
}

const iconPositionMargin = {
  left: 'me-75',
  right: 'ms-75',
  center: 'mx-0'
}

/**
 * @param {object} props
 * @param {string} props.text
 * @param {string} props.className
 * @param {string} props.type
 * @param {boolean} props.block
 * @param {boolean} props.disabled
 * @param {boolean} props.outline
 * @param {string} props.buttonType
 * @param {string} props.color
 * @param {object} props.options
 * @param {function} props.options.onClick
 * @param {boolean} props.options.iconless
 * @param {string} props.options.iconPosition
 * @param {boolean} props.options.lightColor
 * @param {element} props.options.customIcon
 * @returns A Joobpay styled button with the text defined by the type prop
 * @example import { JPButton } from "@Intelli/utilities"
 * @example <JPButton type="save" color="link" buttonType="submit" block={true} options={{iconless: true, onClick: () => console.log('example')}}/>
 */

const JPButton = ({
  id,
  text,
  className,
  type,
  block = defaultProps.block,
  disabled,
  outline,
  active,
  buttonType = defaultProps.buttonType,
  color = defaultProps.color,
  loading,
  options = defaultProps.options,
  size,
  tooltip=false,
  tooltipTextCustom=null,
  style={}
}) => {
  const { t } = useTranslation()

  const buttonOptions = { ...defaultProps.options, ...options }

  const icon = loading ? (
    <FontAwesomeIcon spin icon={faSpinnerThird} className={iconPositionMargin[buttonOptions.iconPosition]} />
  ) : (
    buttonOptions.customIcon || (
      <FontAwesomeIcon icon={buttonIcons[type]} className={iconPositionMargin[buttonOptions.iconPosition]} />
    )
  )

  const btnColor = color !== defaultProps.color ? color : defaultColorsByType[type] || color

  return (
    <>
      <Button
        {...(id ? { id } : {})}
        className={className}
        disabled={disabled || loading}
        outline={outline}
        color={options.lightColor ? `light-${btnColor}` : btnColor}
        type={buttonType}
        block={block}
        onClick={buttonOptions.onClick}
        active={active}
        size={size}
        style={style}
      >
        {buttonOptions.iconless || iconlessButtons.includes(type)
          ? null
          : buttonOptions.iconPosition === 'left'
          ? icon
          : null}
        {buttonOptions.textLess ? null : loading ? t('button.loading') : text || t(`button.${type}`)}
        {buttonOptions.iconless || iconlessButtons.includes(type)
          ? null
          : buttonOptions.iconPosition === 'right'
          ? icon
          : null}
        {buttonOptions.iconless || iconlessButtons.includes(type)
          ? null
          : buttonOptions.iconPosition === 'center'
          ? icon
          : null}
      </Button>
      {tooltip && (
        <UncontrolledTooltip placement="top" target={id}>
          {tooltipTextCustom ? tooltipTextCustom : t(`button.${type}`)}
        </UncontrolledTooltip>
      )}
    </>
  )
}

JPButton.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'save',
    'next',
    'send',
    'back',
    'cancel',
    'delete',
    'add',
    'edit',
    'update',
    'close',
    'submit',
    'reset',
    'save&send',
    'group',
    'skip',
    'action',
    'pay',
    'download'
  ]).isRequired,
  buttonType: PropTypes.oneOf(['button', 'submit', 'reset']),
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'link', 'white', 'green', 'yellow']),
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  active: PropTypes.bool,
  loading: PropTypes.bool,
  options: PropTypes.exact({
    iconless: PropTypes.bool,
    iconPosition: PropTypes.oneOf(['left', 'right', 'center']),
    customIcon: PropTypes.element,
    lightColor: PropTypes.bool,
    onClick: PropTypes.func,
    textLess: PropTypes.bool
  })
}

export default JPButton
