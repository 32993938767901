import ReactFlagsSelect from 'react-flags-select'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const CountrySelector = ({ defaultCountry, setValue, setCountry, disabled }) => {
  const [selected, setSelected] = useState(defaultCountry)
  const { t } = useTranslation()
  return (
    <ReactFlagsSelect
      selected={selected}
      disabled={disabled}
      searchable
      placeholder={t('COUNTRY')}
      searchPlaceholder={t('search')}
      onSelect={code => {
        setSelected(code)
        setValue('country', code)
        setCountry(code)
      }}
    />
  )
}

export default CountrySelector