import { useTranslation } from 'react-i18next'
import AsyncCreatableSelect from 'react-select/async-creatable'

const JpAsyncCreatableSelect = ({
  selectKey,
  field,
  loadOptions,
  onCreate,
  placeHolder = '',
  invalid = false,
  options = {}
}) => {
  const { t } = useTranslation()

  return (
    <AsyncCreatableSelect
      key={selectKey}
      className={`${invalid && 'is-invalid'}`}
      classNames={{
        control: state => `${invalid ? 'is-invalid' : ''}`
      }}
      hideSelectedOptions
      defaultOptions
      loadOptions={loadOptions}
      onCreateOption={onCreate}
      cacheOptions
      placeholder={placeHolder || t('input.select.placeHolder')}
      noOptionsMessage={() => t('input.select.noOptionsMessage')}
      loadingMessage={() => t('input.select.loadingMessage')}
      styles={{
        control: styles => ({
          ...styles,
          borderColor: invalid ? '#EA5455' : styles.borderColor,
          '&:hover': {
            borderColor: invalid ? '#EA5455' : styles['&:hover'].borderColor
          }
        })
      }}
      {...field}
      {...options}
    />
  )
}

export default JpAsyncCreatableSelect
