import { Input } from 'reactstrap'

const JpPhoneInput = ({ name, invalid, className = '', inputOptions }) => {
  return (
    <Input
      id={name || 'phone'}
      name={name || 'phone'}
      type="tel"
      className={`form-control ${invalid ? 'is-invalid' : ''} ${className}`}
      placeholder="111 222 3333"
      invalid={invalid}
      {...inputOptions}
    />
  )
}

export default JpPhoneInput
