import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import { Badge, CardText, UncontrolledTooltip } from 'reactstrap'

const getColorFromPercentage = (percentage, swapBgColor) => {

  let percent = swapBgColor ? (percentage * -1 ): percentage

    if (percent > 0) return 'light-success'
    if (percent < 0) return 'light-danger'
  

  return 'light-info'
}

const getIconFromPercentage = (percentage) => {
  if (percentage > 0) return 'fa fa-arrow-up'
  if (percentage < 0) return 'fa fa-arrow-down'
  return 'fa fa-minus'
}

const JpPercentageText = ({ percentage, periodText, className, swapBgColor }) => {
  // const [tooltipId] = useState(`tooltip${crypto.randomUUID()?.replace(/-/g, '')}`)
  const tooltipId = useMemo(() => `tooltip${crypto.randomUUID()?.replace(/-/g, '')}`, [])
  const percentage_number = percentage ? Number(percentage).toFixed(0) : 0
  return (
    <>
      <CardText
        tag={Badge}
        color={getColorFromPercentage(percentage, swapBgColor)}
        className={`d-flex align-items-center me-50 mb-0 ${
          percentage > 0 ? 'text-success' : 'text-danger'
        } ${className}`}
        {...(periodText ? { id: tooltipId } : {})}
      >
        {/* {percentage > 0 ? '+' : ''} */}
        <FontAwesomeIcon icon={getIconFromPercentage(percentage_number)} className="me-50" />
        {Math.abs(Math.round(percentage_number || 0))}%
      </CardText>
      {periodText && (
        <UncontrolledTooltip className="text-muted" target={tooltipId}>
          {periodText}
        </UncontrolledTooltip>
      )}
    </>
  )
}

export default JpPercentageText
