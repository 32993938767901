import { Card, CardBody, Col, Row } from 'reactstrap'

import JpCountryFlag from '../general/JpCountryFlag'

import JpAvatar from '../general/JpAvatar'
import parseName from '../../functions/parseName'
import JpCardSkeleton from '../skeletons/JpCardSkeleton'

const CardCompany = ({
  companyName,
  country,
  id,
  logo,
  bodyContent,
  cardClassName = '',
  imgClassName = '',
  loading = false
}) => {
  if (loading) return <JpCardSkeleton loading={loading} img={false} />

  return (
    <Card className={`${cardClassName}`}>
      <CardBody>
        <Row>
          <Col xs="8">{bodyContent}</Col>
          <Col className="d-flex justify-content-end align-items-center">
            {logo ? (
              <JpAvatar
                className={`rounded border p-1`}
                imgClassName={`${imgClassName} rounded cursor-default`}
                img={logo}
                imgHeight={'80px'}
                imgWidth={'100%'}
                color="white"
                contentStyles={{
                  maxHeight: 150
                }}
              />
            ) : (
              <JpAvatar color={`light-info`} content={parseName(companyName)} size="xxxl" initials />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default CardCompany
