import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, UncontrolledTooltip } from "reactstrap"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { faCircleExclamation, faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"

const JpTableAction = ({className, onClick, id, icon, tooltipText, Tag, error, disabled, loading=false, lastAction = false}) => {
    

  const ErrorWrapper = ({children}) => (
      <div className="position-relative">
        <FontAwesomeIcon icon={faCircleExclamation} className="text-warning" style={{position: 'absolute', top: '-11px', right: '-9px'}} />
        {children}
      </div>
  )


  const Wrapper = error ? ErrorWrapper : Fragment

    if (Tag && !error && !disabled) {
      return (
        <Wrapper>
          <Tag>
            <FontAwesomeIcon icon={icon} id={id} size="lg"/>
            <UncontrolledTooltip
              placement="top"
              target={id}
            >
              {tooltipText}
            </UncontrolledTooltip>
          </Tag>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        {loading ? <FontAwesomeIcon spin icon={faSpinnerThird} className={`p-0 ${!lastAction ? 'me-1' : ''} table-action`} /> : <Button
              className={`p-0 ${!lastAction ? 'me-1' : ''} table-action ${className}`}
              onClick={onClick}
              color="link"
              disabled={error || disabled}
            >
              <FontAwesomeIcon icon={icon} id={id} size="lg"/>
              <UncontrolledTooltip
                placement="top"
                target={id}
              >
                {tooltipText}
              </UncontrolledTooltip>
        </Button>}
      </Wrapper>
    )
}

// JpTableAction.propTypes = {
//     className: PropTypes.string,
//     onClick: PropTypes.func,
//     id: PropTypes.string,
//     icon: PropTypes.any,
//     tooltipText: PropTypes.string,
//     Tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element])
// }

export default JpTableAction