import { Card, CardHeader, CardBody } from 'reactstrap'

import PropTypes from 'prop-types'

const BlueHeaderCard = ({
  headerContent,
  bodyContent,
  bodyClassName,
  bodyTag,
  cardTag,
  cardClassName,
  cardHeaderClassName,
  color = 'bg-info text-white',
  showHeader = true
}) => {
  return (
    <Card className={`m-0 p-0 ${cardClassName}`} tag={cardTag}>
      {showHeader && (
        <CardHeader className={`${color} pt-75 pb-75 ${cardHeaderClassName}`}>{headerContent}</CardHeader>
      )}
      <CardBody tag={bodyTag} className={`pt-1 ${bodyClassName || ''}`} style={{ height: '100%' }}>
        {bodyContent}
      </CardBody>
    </Card>
  )
}

BlueHeaderCard.propTypes = {
  headerContent: PropTypes.element.isRequired,
  bodyClassName: PropTypes.string,
  bodyContent: PropTypes.element.isRequired,
  bodyTag: PropTypes.string,
  cardTag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  cardClassName: PropTypes.string
}

export default BlueHeaderCard
