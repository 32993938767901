// ** Third Party Components
import Chart from 'react-apexcharts'

// ** Reactstrap Imports
import { Card, CardBody } from 'reactstrap'

const ApexRadiarChart = () => {
  const donutColors = {
    primary: '#0364b6',
    success: '#81C995',
    warning: '#ff9f43',
    danger: '#ea5455',
    info: '#5791e9',
  }

  // ** Chart Options
  const options = {
    legend: {
      show: false,
      position: 'bottom'
    },
    labels: ['Operational', 'Networking', 'Hiring', 'R&D'],

    colors: [donutColors.primary, donutColors.success, donutColors.warning, donutColors.danger, donutColors.info],
    dataLabels: {
      enabled: false,
      formatter(val) {
        return `${parseInt(val)}%`
      }
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat'
            },
            value: {
              fontSize: '0.7rem',
              fontFamily: 'Montserrat',
              formatter(val) {
                return `${parseInt(val)}%`
              }
            },
            total: {
              show: true,
              fontSize: '1rem',
              label: 'Operational',
              formatter() {
                return '31%'
              }
            }
          }
        }
      }
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380
          }
        }
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '1.5rem'
                  },
                  value: {
                    fontSize: '1rem'
                  },
                  total: {
                    fontSize: '1.5rem'
                  }
                }
              }
            }
          }
        }
      }
    ]
  }

  // ** Chart Series
  const series = [85, 16, 50, 50]

  return (
    <Card className='m-0'>
      <CardBody className='m-0 p-0'>
        <Chart className='m-0' options={options} series={series} type='donut' height={200} />
      </CardBody>
    </Card>
  )
}

export default ApexRadiarChart