// ** React Imports
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// ** Third Party Components

import ReactPaginate from 'react-paginate'
import DataTable from 'react-data-table-component'
import produce from 'immer'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'

import { Card, CardHeader, CardTitle, Row, Col, Input, InputGroup, InputGroupText, Progress } from 'reactstrap'

// ** Utils imports
import NoData from '../NoData'
import { SpinnerLoader } from '../../Intelli-utilities'
import { useTranslation } from 'react-i18next'

// funcion piso de altura de la ventana - altura del nav azul / altura de cada fila
const calculatedRows = Math.abs(Math.floor((window.innerHeight - 62 - 47 - 64 - 126) / 60))
const perPage = calculatedRows > 10 ? calculatedRows : 10

const JpTable = ({
  actions,
  title,
  cols,
  statusChange,
  selectable,
  fetcher,
  loading,
  data,
  total,
  queriableData,
  options,
  showSearch = true,
  cardClassName=''
}) => {
  // ** States
  const [currentPage, setCurrentPage] = useState(0)
  //   const [total, setTotal] = useState(0)
  //   const [data, setData] = useState([])
  //   const [loading, setLoading] = useState(false)
  const [queries, setQueries] = useState(
    queriableData || [
      /* {
      field: 'first_name',
      operator: 'like',
      value: ''
    } */
      {
        field: 'query',
        value: ''
      }
    ]
  )

  const { t } = useTranslation()

  let timeout

  const updateData = (page, limit, q) => {
    fetcher({ page, limit, q })
  }

  // ** Function to handle Pagination
  const handlePagination = ({ selected }) => {
    setCurrentPage(selected)
  }

  const _handleSearch = ({ target: { value } }) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      const nextState = produce(queries, draft => {
        draft[0].value = value
      })
      setQueries(nextState)
    }, 500)
  }

  // ** Custom Pagination
  const CustomPagination = () => (
    <ReactPaginate
      nextLabel=""
      breakLabel="..."
      previousLabel=""
      pageRangeDisplayed={2}
      forcePage={currentPage}
      marginPagesDisplayed={2}
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      breakLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextClassName="page-item next-item"
      previousClassName="page-item prev-item"
      pageCount={Math.ceil(total / perPage) || 1}
      onPageChange={page => handlePagination(page)}
      containerClassName="pagination react-paginate pagination-sm separated-pagination justify-content-end pe-1 mt-1"
    />
  )

  useEffect(() => {
    updateData(currentPage * perPage, perPage, queries) // fetch page 1 of users
  }, [statusChange, currentPage, queries])

  return (
    <Card className={`overflow-hidden ${cardClassName}`}>
      {title && !options?.customTitle ? (
        <CardHeader className="pb-1">
          <CardTitle tag="h4">{title}</CardTitle>{' '}
        </CardHeader>
      ) : (
        <></>
      )}
      {options?.customTitle ? <CardHeader>{options.customTitle}</CardHeader> : <></>}
      <Row className="justify-content-start mx-0 my-1">
        {/* Filter */}
        {showSearch ? (
          <Col className="d-flex align-items-center justify-content-end" md="3" sm="12">
            <InputGroup className="input-group-merge w-100" md="4">
              <InputGroupText>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputGroupText>
              <Input
                type="text"
                id="search-input"
                placeholder={t('input.search.placeholder')}
                onChange={_handleSearch}
              />
            </InputGroup>
          </Col>
        ) : (
          ''
        )}
        {/* Actions */}
        {actions && <Col className={`${options?.actionsClassName || ''}`}>{actions}</Col>}
      </Row>
      <div className="react-dataTable">
        <DataTable
          noHeader
          pagination
          title={title}
          selectableRows={selectable || false}
          data={data}
          columns={cols}
          progressPending={loading}
          progressComponent={<SpinnerLoader />}
          persistTableHead
          noDataComponent={<NoData color="light" size="5x" />}
          className="react-dataTable"
          sortIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" className="ms-75" />}
          paginationComponent={CustomPagination}
          paginationTotalRows={total}
          paginationDefaultPage={currentPage + 1}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
        />
      </div>
    </Card>
  )
}

JpTable.propTypes = {
  actions: PropTypes.element,
  title: PropTypes.string,
  cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  statusChange: PropTypes.bool.isRequired,
  selectable: PropTypes.bool,
  fetcher: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.any]),
  queriableData: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.string
    })
  ),
  options: PropTypes.shape({
    perPage: PropTypes.number,
    customTitle: PropTypes.element,
    actionsClassName: PropTypes.string
  })
}

export default JpTable
