import { Badge, Card, CardBody, CardHeader, CardText, CardTitle, Col, Placeholder, Progress, Row } from 'reactstrap'
import JpRadialBar from '../graphs/JpRadialBar'
import { PRODUCTUVITY_STATUS_VALUES } from '../../constants/productivity'
import JpAvatar from '../general/JpAvatar'
import JpGraphSkeleton from '../skeletons/JpGraphSkeleton'

const JpRadialBarCard = ({
  title,
  colors,
  data,
  tooltipFormatter,
  legendFormatter,
  className,
  bodyClassName,
  rawData,
  loading = false
}) => {
  return (
    <Card className={className}>
      {title && (
        <CardHeader className="pb-0">
          {loading ? (
            <Placeholder className="col-6" size="lg" animation="glow">
              <Placeholder className="col-12 rounded" size="lg" />
            </Placeholder>
          ) : (
            <CardTitle>{title}</CardTitle>
          )}
        </CardHeader>
      )}
      <CardBody
        className={`d-flex align-items-center justify-content-between ${
          loading ? 'pe-xl-3' : 'pb-0 pe-0'
        } ${bodyClassName}`}
      >
        {loading ? (
          <div className="w-100">
            {[1, 1, 1, 1].map((_, index) => (
              <CardText className={`d-flex placeholder-glow`} key={index}>
                <span className="placeholder col-7 rounded">...</span>
              </CardText>
            ))}
          </div>
        ) : (
          <div>
            {Object.entries(rawData).map(([key, value], index) => (
              <div className="mb-1" key={index}>
                <Badge color={`light-${PRODUCTUVITY_STATUS_VALUES[key]?.color}`}>
                  {legendFormatter(key, {}, rawData)}
                </Badge>
              </div>
            ))}
          </div>
        )}

        <div className="d-block d-md-none d-xl-block">
          {loading ? (
            <JpGraphSkeleton />
          ) : (
            <JpRadialBar
              rawData={rawData}
              colors={colors}
              data={data}
              tooltipFormatter={tooltipFormatter}
              legendFormatter={legendFormatter}
            />
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default JpRadialBarCard
