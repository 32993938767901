import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const JpIcon = ({ id, icon = 'joobpay', size, className, kit = false, iconOptions, props }) => {
  //   return <i className={`fa fa-${icon} fa-${size} ${isconOptions?.animation} ${className}`} {...iconOptions} />
  return (
    <FontAwesomeIcon
      id={id}
      icon={`${!kit ? 'fa' : 'fak'} fa-${icon}`}
      size={size}
      {...iconOptions}
      className={className}
      {...props}
    />
  )
}

export default JpIcon
