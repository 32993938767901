import { Star } from 'react-feather'
import Rating from 'react-rating'

const RatingStars = ({
  initialRating = 0,
  fractions = 2,
  readonly = false,
  onChange = () => {},
  color = '#FF9F43'
}) => {
  return (
    <div>
      <Rating
        initialRating={initialRating}
        fractions={fractions}
        readonly={readonly}
        onChange={onChange}
        emptySymbol={<Star fill="#babfc7" stroke="#babfc7" />}
        fullSymbol={<Star fill={color} stroke={color} />}
      />
    </div>
  )
}

export default RatingStars
