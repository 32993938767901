import ReactCountryFlag from "react-country-flag"
import { UncontrolledTooltip } from "reactstrap"
import { getDataSet, reduce } from 'iso3166-2-db'
import i18next from 'i18next'

const JpCountryFlag = ({ countryCode, id }) => {

    const listOfCountries = reduce(getDataSet(), i18next.language)

        {/* <JpCountryFlag
            countryCode = { row?.country }
            id = { row?.contractor_id }
        /> */}

    return (
        <>

            <ReactCountryFlag
                countryCode={countryCode}
                svg
                style={{ width: '1.5rem ', height: '1.5rem' }}
                id={`country-${id}`}
            />
            {id && <UncontrolledTooltip placement="top" target={`country-${id}`}>
                {listOfCountries[countryCode]?.name}
            </UncontrolledTooltip>}


        </>
    )
}

export default JpCountryFlag