export const PRODUCTIVITY_DASHBOARD = {
  ACTIVE: {
    color: 'success',
    iconAvatar: 'fa-solid fa-person-rays',
    showApp: true
  },
  PRODUCTIVE: {
    color: 'success',
    iconAvatar: 'fa-solid fa-person-rays',
    showApp: true
  },
  UNPRODUCTIVE: {
    color: 'danger',
    iconAvatar: 'fa-solid fa-person-rays',
    showApp: true
  },
  NEUTRAL: {
    color: 'primary',
    iconAvatar: 'fa-solid fa-person-rays',
    showApp: true
  },
  ON_CALL: {
    color: 'success',
    iconAvatar: 'fa-solid fa-person-rays',
    showApp: true
  },
  AFK: {
    color: 'warning',
    iconAvatar: 'fa-solid fa-laptop-slash',
    showApp: true
  },
  DISCONNECTED: {
    color: 'light',
    iconAvatar: 'fa-kit fa-solid-plug-slash',
    showApp: false
  },
  CLOCK_OUT: {
    color: 'secondary',
    iconAvatar: 'fa-solid fa-arrow-right-from-bracket',
    showApp: false
  }
}
