import { useState } from "react"
import { useEffect } from "react"

export default function  GeoLocation () {
    const [location, setLocation] = useState(null)
    useEffect(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords
            setLocation({ latitude, longitude })
          },
          error => {
            console.error(error)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }, [])
    const [geoLocation, setGeoLocation] = useState(null)

    useEffect(() => {
      if (location) {
        async function fetchLocation() {
          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${location.latitude}&lon=${location.longitude}`
          const response = await fetch(url)
          const data = await response.json()
          setGeoLocation(data)
        }
        fetchLocation()
      }
    }, [location])
    return(
        geoLocation
    )
}