import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import JpAvatar from '../general/JpAvatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

const JpHorizontalAvatarCard = ({ title = '', data = {}, loading = false, cardClassName,cardHeaderClassName }) => {
  return (
    <Card className={`${cardClassName ? cardClassName : ''}`}>
      <CardHeader className={`${loading ? 'row placeholder-glow' : '' } ${cardHeaderClassName ? cardHeaderClassName : ''}`}>
        {loading ? (
          <CardTitle tag={Col} lg="4" className="ms-1 placeholder rounded">
            ...
          </CardTitle>
        ) : (
          <CardTitle className="ps-2">{title}</CardTitle>
        )}
      </CardHeader>
      <CardBody className="row align-items-center justify-content-center">
        {loading &&
          [1, 1, 1, 1].map((val, index) => (
            <Fragment key={index}>
              <Col lg="1" className="ps-1">
                <div className="placeholder-glow">
                  <div className="placeholder rounded-circle">
                    <JpAvatar size="lg" color="light-secondary" content="" />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="placeholder-glow">
                  <div className="d-flex flex-column">
                    <Row>
                      <small className="placeholder rounded col-6 mb-75">...</small>
                    </Row>
                    <Row>
                      <span className="fw-bold placeholder rounded col-12">...</span>
                    </Row>
                  </div>
                </div>
              </Col>
            </Fragment>
          ))}
        {data?.length > 0 && !loading ? (
          data?.map((item, index) => (
            <Col
              {...item.colOptions}
              className="d-flex justify-content-start justify-content-sm-center align-items-center mt-1 mt-md-0"
              key={index}
            >
              <JpAvatar
                size="lg"
                className="me-1"
                color={item?.color}
                {...(!!item?.content
                  ? { content: item?.content }
                  : { icon: <FontAwesomeIcon className="cursor-default" icon={item?.icon} /> })}
              />
              <div className="d-flex flex-column">
                <small>{item?.name}</small>
                <span className="fw-bold">{item?.stat}</span>
              </div>
            </Col>
          ))
        ) : (
          <></>
        )}
      </CardBody>
    </Card>
  )
}

export default JpHorizontalAvatarCard
