import Select, { components } from 'react-select'
import ReactCountryFlag from 'react-country-flag'
import {
    getCountries,
    getPhoneCode
} from 'libphonenumber-js'
import { getDataSet, reduce } from 'iso3166-2-db'
import i18next from 'i18next'

const listOfCountries = reduce(getDataSet(), i18next.language)

const OptionComponent = ({ data, ...props }) => {
    
    if (data.value === 'ALL') return <components.Option {...props} />

    return (
      <components.Option {...props}>
        <ReactCountryFlag
          svg
          className="country-flag flag-icon me-75"
          countryCode={data.value}
        />
        {data.label}
      </components.Option>
    )
}

const SingleValue = ({children, ...props}) => {
    const {data} = props

    return (
        <components.SingleValue {...props}>
            <ReactCountryFlag
                svg
                className="country-flag flag-icon me-75"
                countryCode={data.value}
            />
            {children}
        </components.SingleValue>)
}

const JpCountryCodesInput = ({field, isClearable = true, includeAllCountriesOption = false, onChange, defaultValue}) => {

    const countryOptions = getCountries().filter(country => !!listOfCountries[country]).map((country, index) => {
        return {value: country, label: `${listOfCountries[country]?.name || country} (+${getPhoneCode(country)})`}
    })
    
    if (includeAllCountriesOption) countryOptions.unshift({value: 'ALL', label: 'All'})

    return (
      <Select
        defaultValue={defaultValue}
        className="w-100"
        onChange={onChange}
        options={countryOptions}
        isClearable={isClearable}
        components={{
          Option: OptionComponent,
          SingleValue
        }}
        {...field}
      />
    )
}

export default JpCountryCodesInput